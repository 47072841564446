var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-50 d-flex-center flex-column gap-1 align-items-stretch"
  }, _vm._l(_vm.cancelFlightList, function (flight, flightIndex) {
    return _c('div', {
      key: (flight === null || flight === void 0 ? void 0 : flight.segmentId) || flightIndex,
      staticClass: "d-flex-center justify-content-around border-danger border-2 rounded-8px bg-light-danger fw-700 font-medium-3",
      staticStyle: {
        "border-width": "1.5px !important"
      }
    }, [_c('IAmLogoAirline', {
      attrs: {
        "right": "",
        "airline": flight.operating && flight.operating.length === 2 ? flight.operating : flight.airline,
        "size": "sm"
      }
    }), _c('span', {}, [_vm._v(_vm._s(flight.airline) + _vm._s(flight.flightNumber))]), _c('div', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(flight.departure.at, flight.departure.timeZone || flight.departure.timezone).time) + " - " + _vm._s(_vm.convertISODateTime(flight.arrival.at, flight.arrival.timeZone || flight.arrival.timezone).time) + " ")]), _c('div', {
      staticClass: "text-dark"
    }, [_vm._v(" " + _vm._s(flight.departure.iataCode) + " - " + _vm._s(flight.arrival.iataCode) + " ")]), _c('BBadge', {
      staticClass: "rounded-pill px-1 py-50",
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation.CANCELLED')) + " ")])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }