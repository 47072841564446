<template lang="">
  <div class="my-50 d-flex-center flex-column gap-1 align-items-stretch">
    <div
      v-for="(flight, flightIndex) of cancelFlightList"
      :key="flight?.segmentId || flightIndex"
      class="d-flex-center justify-content-around border-danger border-2 rounded-8px bg-light-danger fw-700 font-medium-3"
      style="border-width: 1.5px !important;"
    >
      <IAmLogoAirline
        right
        :airline="flight.operating && flight.operating.length === 2 ? flight.operating : flight.airline"
        size="sm"
      />

      <span class="">{{ flight.airline }}{{ flight.flightNumber }}</span>
      <div class="text-dark">
        {{ convertISODateTime( flight.departure.at, flight.departure.timeZone || flight.departure.timezone ).time }}
        -
        {{ convertISODateTime( flight.arrival.at, flight.arrival.timeZone || flight.arrival.timezone ).time }}
      </div>

      <div class="text-dark">
        {{ flight.departure.iataCode }} - {{ flight.arrival.iataCode }}
      </div>

      <BBadge
        class="rounded-pill px-1 py-50"
        variant="danger"
      >
        {{ $t('reservation.CANCELLED') }}
      </BBadge>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import { BBadge } from 'bootstrap-vue'

import store from '@/store'

import { convertISODateTime } from '@core/utils/filter'

export default {
  components: {
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
    BBadge,
  },
  setup() {
    const cancelFlightList = computed(() => store.getters['app-reservation/getCancelFlight'])
    return { cancelFlightList, convertISODateTime }
  },
}
</script>
<style lang="">
</style>
